import { Platform } from ":shared/components/loaders";

export default function Loader1(props?: { title?: string; msg?: string; isolatedTitle?: boolean }) {
  return (
    <Platform
      class="relative flex flex-col w-full h-full items-center justify-center "
      title={props.isolatedTitle ? props.title ?? "Designathon 2024" : `Designathon 2024 ${props.title ?? ""}`}
      msg={props.msg}
    />
  );
}
